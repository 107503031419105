import { registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import localeItExtra from '@angular/common/locales/extra/it';
import localeIt from '@angular/common/locales/it';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  LOCALE_ID,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { environment } from '@environments/environment';
import { HttpResponseInterceptor } from '@interceptors/HttpResponseInterceptor.interceptor';
import { HttpCustomHeaders } from '@interfaces/enum/HttpCustomHeaders.enum';
import { SessionStorageKeys } from '@interfaces/enum/SessionStorageKeys.enum';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { lastValueFrom, map } from 'rxjs';
import { routes } from './app.routes';

registerLocaleData(localeIt, 'it-IT', localeItExtra);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptors([HttpResponseInterceptor])),
    importProvidersFrom(
      SweetAlert2Module.forRoot({
        provideSwal: () => import('sweetalert2'),
      }),
    ),
    { provide: LOCALE_ID, useValue: 'it-IT' },
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      multi: true,
      deps: [HttpClient],
    },
  ],
};

function init(http: HttpClient) {
  return () => {
    const obs$ = http
      .head(`${environment.cws}/area-merchandising/auth/init`, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map((res) => {
          const token = res?.headers?.get(HttpCustomHeaders.X_MER_XSRF_TOKEN);
          if (!token) throw new Error('Cannot get CSRF Token');
          sessionStorage.setItem(SessionStorageKeys.XSRF_TOKEN, token);
          return true;
        }),
      );

    return lastValueFrom(obs$);
  };
}
